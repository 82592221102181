import React from 'react';
import ContentContainer from '../containers/ContentContainer';
import NewsSvg from '../assets/man-browsing.svg'; // Placeholder for your SVG asset
import '../styles/ContentContainer.css';

const TailoredNews = () => {
    return (
      <ContentContainer>
        <section className="section animated-fadeIn">
          <h1>Flexible AI-Powered News</h1>
          <img src={NewsSvg} alt="Flexible AI News" className="img-right"/>
          <p className="highlight-text-center">Your News, Enhanced by AI</p>
  
          <div className="section-text">
            <h2>Adaptable News Experience</h2>
            <p>gavAI introduces an innovative approach to news consumption. Our platform is built with a focus on flexibility and choice, allowing you to access a wide range of news topics and styles without any prerequisite for personal data analysis.</p>
            
            <h3>Extensive Topic Selection</h3>
            <p>Choose from hundreds of topics and categories. Whether you're interested in technology, lifestyle, world events, or uplifting stories, our platform has you covered with diverse, pre-curated content selections.</p>
            
            <h3>Optional Personalization</h3>
            <p>If you opt-in, our AI algorithms can further tailor the news to your preferences. This personalization is entirely under your control, ensuring a news feed that reflects your interests while respecting your privacy.</p>
            
            <h3>User-Centric Interface</h3>
            <p>Our interface is designed for ease of use and adaptability. Whether you're browsing through our extensive pre-set topics or using AI-enhanced personalization features, your news experience is smooth, intuitive, and engaging.</p>
            
            <h3>Empowerment and Control</h3>
            <p>At gavAI, we empower you with complete control over your news experience. Decide how much or how little personalization you want. Our platform ensures a rich and varied news consumption experience, tailored to your comfort level with AI integration.</p>
          </div>
        </section>
      </ContentContainer>
    );
};

export default TailoredNews;
