import React from 'react';
import ContentContainer from '../containers/ContentContainer';
import PersonCreatingSvg from '../assets/person-creating.svg';
import StartupSvg from '../assets/startup-launch.svg';
import InnovationSvg from '../assets/innovation.svg'; // Assuming you have this or a similar image
import '../styles/About.css';

const AboutUs = () => {
  return (
    <ContentContainer>
      <section className="section animated-fadeIn">
        <h1>About gavAI</h1>

        {/* Introduction Section */}
        <div className="section">
          <img src={PersonCreatingSvg} alt="Person Creating" className="img-right" />
          <div>
            <h2>Our Mission and Vision</h2>
            <p>Welcome to gavAI, a fresh venture in the AI space, established in the dynamic tech environment of Phoenix, Arizona. Here, the ambition is to blend innovative technology with practical, user-centric applications.</p>
            <p>As the founder, Gavin Volpe, embarks on this journey, gavAI LLC is more than just a startup; it's a personal mission. The focus is to bring AI's empowering capabilities to individuals and small businesses, making advanced technology accessible and beneficial for everyday tasks.</p>
          </div>
        </div>

        {/* Our Beginning Section */}
        <div className="section">
          <img src={StartupSvg} alt="Startup" className="img-left" />
          <div>
            <h2>The Early Days</h2>
            <p>gavAI is at the very start of its journey. The initial steps have been taken with enthusiasm and a clear vision. Although in its early days, the aim is to gradually grow into a hub of AI innovation and practical solutions.</p>
            <p>The venture is still finding its footing, yet it's fueled by a desire to delve into the possibilities of AI and make a tangible difference in people's lives.</p>
          </div>
        </div>

        {/* Vision and Aspirations Section */}
        <div className="section">
          <img src={InnovationSvg} alt="Innovation" className="img-right" />
          <div>
            <h2>Looking to the Future</h2>
            <p>At gavAI, the journey has just begun. The roadmap ahead is filled with opportunities to innovate and challenges to overcome. The commitment is to explore and harness AI's potential in ways that are ethical, practical, and enhance human capabilities.</p>
            <p>As the story of gavAI unfolds, join in witnessing and contributing to the growth of a venture that aspires to make a real difference through the power of AI.</p>
          </div>
        </div>

      </section>
      {/* Additional sections can be added here */}
    </ContentContainer>
  );
};

export default AboutUs;
