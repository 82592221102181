import React from 'react';
import { Link } from 'react-router-dom';
import ContentContainer from '../containers/ContentContainer';
import '../styles/ContentContainer.css';
import { Icon } from '@mdi/react';
import { mdiRobot, mdiTools, mdiLinkVariant, mdiNewspaper } from '@mdi/js';
import DevToolsSvg from '../assets/women-web-developer-with-laptop.svg';
import Integrate from '../assets/cloud-storage.svg';
import CreateSvg from '../assets/person-creating.svg';
import NewsSvg from '../assets/people-using-technology.svg';
const Services = () => {
    return (
      <ContentContainer>
        <section className="section animated-fadeIn" style={{textAlign: 'center'}}>
          <h1>Explore Our Services</h1>
          <p className="highlight-text-center">Empowering Your Digital Journey with AI</p>
          <p style={{marginBottom: '10px', textAlign: 'center'}}>For detailed pricing information, visit our Pricing Page</p>
          <Link to="/pricing" className="button-primary" >Pricing Page</Link>
        </section>
        <section className="section animated-fadeIn"  style={{textAlign: 'right'}}>
          <img src={DevToolsSvg} alt="Dev Tools" className="img-left" />
          <h2><Icon path={mdiRobot} size={1} className="list-icon" /> Personal AI Assistant</h2>
          <p>Unlock your creative and learning potential with our customizable AI assistant.</p>
          <Link to="/services/models" className="button-primary">Learn More</Link>
        </section>
        <section className="section animated-fadeIn">
          <img src={CreateSvg} alt="create" className="img-right" />
          <h2 ><Icon path={mdiTools} size={1} className="list-icon" /> Dev Tools</h2>
          <p>Revolutionize your development process with AI-driven tools and solutions.</p>
          <Link to="/services/dev-tools" className="button-primary">Learn about our Dev Tools</Link>
        </section>
        <section className="section animated-fadeIn"  style={{textAlign: 'right'}}>
          <img src={Integrate} alt="Integrations" className="img-left" />
          <h2><Icon path={mdiLinkVariant} size={1} className="list-icon" /> Seamless Integrations</h2>
          <p>Integrate and streamline your digital tools for a unified, efficient experience.</p>
          <Link to="/services/integrations" className="button-primary">Explore Integrations</Link>
        </section>
        <section className="section animated-fadeIn">
        <img src={NewsSvg} alt="News" className="img-right" />
          <h2><Icon path={mdiNewspaper} size={1} className="list-icon" /> Flexible AI-Powered News</h2>
          <p>Customize your news experience with our AI-powered, adaptable platform.</p>
          <Link to="/services/tailored-news" className="button-primary">Learn About AI News</Link>
        </section>
        <section className="section animated-fadeIn">
        <h2>Join Our Waitlist</h2>
        <p>
          Excited about what's coming? Sign up for our waitlist to be notified when our services are available.
        </p>
        <Link to="/waitlist" className="button-primary">Join the Waitlist</Link>
        </section>
      </ContentContainer>
    );
};

export default Services;
