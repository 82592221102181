// src/components/StarBackground.js
import React, { useState, useEffect, useContext } from 'react';
import '../styles/StarBackground.css';
import { WarpContext } from '../contexts/WarpContext';

// Helper function outside of your component to generate stars
function generateStars(number) {
  return Array.from({ length: number }).map((_, index) => (
    <div
      key={index}
      className={`star`}
      style={{
        top: `${Math.random() * 100}%`,
        left: `${Math.random() * 100}%`,
        animationDuration: `${Math.random() * 3 + 1}s`,
        opacity: Math.random(),
      }}
    />
  ));
}
const StarBackground = () => {
  const { isWarp } = useContext(WarpContext);
  const [animationClass, setAnimationClass] = useState('');

  // Generate stars initially and keep them persistent
  const [stars] = useState(() => generateStars(100));

  useEffect(() => {
    if (isWarp) {
      // Start warp animation
      setAnimationClass('warp');
      // Reset animation class after warp is finished
      const timer = setTimeout(() => setAnimationClass(''), 1000);
      return () => clearTimeout(timer);
    }
  }, [isWarp]);

  return <div className={`stars ${animationClass}`}>{stars}</div>;
};

export default StarBackground;
