// src/utils/device.js
export const isMobile = () => {
    if (typeof navigator === 'undefined') return false;
  
    const toMatch = [
      /Android/i,
      /webOS/i,
      /iPhone/i,
      /iPad/i,
      /iPod/i,
      /BlackBerry/i,
      /Windows Phone/i
    ];
  
    return toMatch.some((toMatchItem) => {
      return navigator.userAgent.match(toMatchItem);
    });
  };
  
export const isIphone = () => {
    return /iPhone/.test(navigator.userAgent) && !window.MSStream;
  };
  