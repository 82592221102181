import React, { useState, useEffect } from 'react';
import ContentContainer from '../containers/ContentContainer';
import '../styles/Waitlist.css'; // Your CSS file

const WaitlistPage = () => {
  const [formData, setFormData] = useState({ email: '', use: '', comments: '', receiveConfirmation: false, receiveUpdate: false });
  const [removeData, setRemoveData] = useState({ email: '' });
  const [captchaToken, setCaptchaToken] = useState('');
  const [captchaAction, setCaptchaAction] = useState(''); // New state to track the current action
  const [showCaptcha, setShowCaptcha] = useState(false);
  const [isCaptchaVerified, setIsCaptchaVerified] = useState(false);
  const [isCaptchaScriptLoaded, setIsCaptchaScriptLoaded] = useState(false);

  useEffect(() => {
    if (!document.querySelector('script[src="https://b7405edef359.edge.captcha-sdk.awswaf.com/b7405edef359/jsapi.js"]')) {
      const script = document.createElement('script');
      script.src = 'https://b7405edef359.edge.captcha-sdk.awswaf.com/b7405edef359/jsapi.js';
      script.defer = true;
      script.onload = () => setIsCaptchaScriptLoaded(true);
      document.body.appendChild(script);
    } else {
      setIsCaptchaScriptLoaded(true);
    }
  }, []);
  const handleRemoveChange = (e) => {
    setRemoveData({ ...removeData, [e.target.name]: e.target.value });
  };
  // Update handleChange to handle checkbox state
  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData({ 
      ...formData, 
      [name]: type === 'checkbox' ? checked : value 
    });
    if (type !== 'checkbox') {
      autoResizeTextArea(e.target);
    }
  };

  const addToWaitlist = async () => {
    const requestBody = {
      httpMethod: "POST",
      body: JSON.stringify(formData)
    };
    try {
      const apiGatewayUrl = 'https://22alawmxu2.execute-api.us-east-1.amazonaws.com/prod/addToWaitlist';
      const response = await fetch(apiGatewayUrl, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(requestBody),
      });

      if (response.ok) {
        alert('Successfully added to waitlist!');
        setFormData({ email: '', use: '', comments: '', receiveConfirmation: false, receiveUpdate: false });
      } else {
        const errorData = await response.json();
        alert(`${errorData.message}`);
      }
    } catch (error) {
      console.error('Error:', error);
      alert('An error occurred. Please try again later.');
    }
  };

  const removeFromWaitlist = async () => {
    const removeRequestBody = {
      httpMethod: "POST",
      body: JSON.stringify(removeData)
    };
    try {
      const apiGatewayUrl = 'https://22alawmxu2.execute-api.us-east-1.amazonaws.com/prod/deleteFromWaitlist';
      const response = await fetch(apiGatewayUrl, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(removeRequestBody),
      });
  
      if (response.ok) {
        const data = await response.json();
        alert('Successfully removed from waitlist.');
        setRemoveData({email: ''})
      } else {
        const errorData = await response.json();
        alert(`Failed to remove from waitlist: ${errorData.message}`);
      }
    } catch (error) {
      console.error('Error:', error);
      alert('An error occurred. Please try again later.');
    }
  };
  

  const handleCaptchaVerify = async (token) => {
    setCaptchaToken(token);
    setIsCaptchaVerified(true);

    if (captchaAction === 'add') {
      addToWaitlist();
    } else if (captchaAction === 'remove') {
      removeFromWaitlist();
    }
  };

  const autoResizeTextArea = (element) => {
    element.style.height = 'auto';
    element.style.height = element.scrollHeight + 'px';
  };


  

  const renderCaptcha = () => {
    if (window.AwsWafCaptcha && document.getElementById('captcha-container')) {
      window.AwsWafCaptcha.renderCaptcha(document.getElementById('captcha-container'), {
        apiKey: process.env.REACT_APP_CAPTCHA_API_KEY, // Replace with your actual API key
        onSuccess: handleCaptchaVerify,
        onError: (error) => console.error("Captcha Error:", error),
        // Add other configuration parameters as needed
      });
    }
  };


  // Adjusted to control where the captcha should be rendered
  const Captcha = () => {
    useEffect(() => {
      if (!isCaptchaVerified && showCaptcha) {
        renderCaptcha();
      }
    }, [isCaptchaVerified, showCaptcha]);

    return !isCaptchaVerified && showCaptcha && <div id="captcha-container"></div>;
  };

  // Adjust handleSubmit and handleRemoveSubmit to reset captcha state
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (formData.email) {
      setCaptchaAction('add');
      setShowCaptcha(true);
      setIsCaptchaVerified(false);
    } else {
      alert('Please provide an email address.');
    }
  };

  const handleRemoveSubmit = async (e) => {
    e.preventDefault();
    if (removeData.email) {
      setCaptchaAction('remove');
      setShowCaptcha(true);
      setIsCaptchaVerified(false);
    } else {
      alert('Please provide an email address for removal.');
    }
  };
  return (
    <ContentContainer>
      <div className="waitlist-container">
        <section className="waitlist-section">
          <h1>Join Our Waitlist</h1>
            
            <form className="waitlist-form" onSubmit={handleSubmit}>
              {/* Form Fields */}
              <label htmlFor="email">Email</label>
              <input
                style={{width: '100%'}}
                type="email"
                id="email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                required
                placeholder="example@email.com" // Example placeholder text
              />
              <label htmlFor="use">Explain Your Use Case</label>
              <textarea
                id="use"
                name="use"
                value={formData.use}
                onChange={handleChange}
                required
                placeholder="E.g., I plan to use gavAI for automating my daily tasks..." // Example placeholder text
              />

              <label htmlFor="comments">Thoughts, Comments, Concerns, Suggestions</label>
              <textarea
                id="comments"
                name="comments"
                value={formData.comments}
                onChange={handleChange}
                placeholder="Any specific features you think were missing? Let us know here..." // Example placeholder text
              />
              <div className="checkbox-container">
                <input
                  type="checkbox"
                  id="receiveConfirmation"
                  name="receiveConfirmation"
                  className="checkbox"
                  checked={formData.receiveConfirmation}
                  onChange={handleChange}
                />
                <label htmlFor="receiveConfirmation" className="checkbox-label">
                  Receive a confirmation email
                </label>
              </div>

              <div className="checkbox-container">
                <input
                  type="checkbox"
                  id="receiveUpdate"
                  name="receiveUpdate"
                  checked={formData.receiveUpdate}
                  onChange={handleChange}
                />
                <label htmlFor="receiveUpdate" className="checkbox-label">
                  Receive an email when gavAI is available
                </label>
              </div>
              


            {/* Captcha Component */}
            {captchaAction === 'add' && <Captcha />}
            {(!showCaptcha || captchaAction !== 'add') && (
              <button type="submit">Join Waitlist</button>
            )}
          </form>


            {/* New section for removal from waitlist */}

              <h2>Remove from Waitlist</h2>
              <p style={{textAlign: 'center'}}>If you wish to be removed from our waitlist, please enter your email address below.</p>
              <p style={{textAlign: 'center'}}>This will remove all your information from our system.</p>
              <form className="waitlist-form" onSubmit={handleRemoveSubmit}>
                <label htmlFor="removeEmail">Email</label>
                <input
                  style={{width: '100%'}}
                  type="email"
                  id="removeEmail"
                  name="email"
                  value={removeData.email}
                  onChange={handleRemoveChange}
                  required
                  placeholder="example@email.com"
                />
                {captchaAction === 'remove' && <Captcha />}
                {(!showCaptcha || captchaAction !== 'remove') && (
                  <button type="submit">Remove from Waitlist</button>
                )}
              </form>
        </section>
      </div>
    </ContentContainer>
  );
};

export default WaitlistPage;