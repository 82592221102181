// src/components/Header.js

import React, { useState, useEffect } from 'react';
import HeaderMenu from './HeaderMenu';
import '../styles/Header.css';
import '../styles/HeaderMenu.css';
import '../styles/Logo.css';
import { useNavigate } from 'react-router-dom';
import Icon from '@mdi/react';
import { mdiChevronUp, mdiChevronDown } from '@mdi/js';
import AnimatedLogo from './AnimatedLogo';
import { useHeaderContext } from '../contexts/HeaderContext';

const Header = () => {
  const { activeItem, setActiveItem } = useHeaderContext();
  const navigate = useNavigate();
  const [activeMenu, setActiveMenu] = useState('');
  const [prevScrollPos, setPrevScrollPos] = useState(window.pageYOffset);
  const [visible, setVisible] = useState(true);
  const [menuTimeout, setMenuTimeout] = useState(null);

  const handleScroll = () => {
    // Current scroll position
    const currentScrollPos = window.pageYOffset;
    // Set state based on scroll direction
    setVisible(prevScrollPos > currentScrollPos || currentScrollPos < 10);
    // Update the previous scroll position
    setPrevScrollPos(currentScrollPos);
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);

    return () => window.removeEventListener('scroll', handleScroll);
  }, [prevScrollPos, visible, handleScroll]);

  const handleRedirect = (section) => {
    let path; // Declare 'path' variable outside the if-else block

    if (section === 'home') { // Fixed the syntax error here
      path = '/';
    } else {
      path = `/${section}`; // Fixed the syntax error here
    }

    setActiveItem(path); // Set the active item for styling purposes
    navigate(path); // Redirect to the section's overview page
  };


  const handleMouseEnter = (menuName) => {
    // Clear any existing timeouts to prevent the menu from hiding
    if (menuTimeout) {
      clearTimeout(menuTimeout);
      setMenuTimeout(null);
    }
    setActiveMenu(menuName);
  };

  const handleMouseLeave = () => {
    // Set a timeout before hiding the menu
    const timeout = setTimeout(() => {
      setActiveMenu('');
    }, 500); // Delay of 500 milliseconds
    setMenuTimeout(timeout);
  };

  const getIconPath = (menuName) => {
    return activeMenu === menuName ? mdiChevronUp : mdiChevronDown;
  };

  return (
    <header className={`header ${!visible ? 'hidden' : ''}`}>
      <AnimatedLogo />
      <div style={{ display: 'flex', justifyContent: 'flex-end', fontSize: '24px', fontWeight: '600' }}>  
        {/* Iterate over each menu item, passing the section name to handleRedirect */}
        {['home', 'services', 'pricing', 'about', 'privacy', 'contact', 'waitlist'].map((section) => (
          <div style={{ marginRight: '20px', position: 'relative' }}
               onMouseEnter={() => handleMouseEnter(section)}
               onMouseLeave={handleMouseLeave}
               onClick={() => handleRedirect(section)}>
            <span className={`menu-item ${activeItem === `/${section}` ? 'active' : ''}`}>
              {section.charAt(0).toUpperCase() + section.slice(1)} {/* Capitalize the first letter */}
              <Icon path={getIconPath(section)} size={1} />
            </span>
            {/* No need to pass item to HeaderMenu, it will handle submenu items internally */}
            <HeaderMenu section={section} className={activeMenu === section ? 'header-menu active' : 'header-menu'} />
          </div>
        ))}
      </div>
    </header>
  );  
};

export default Header;
