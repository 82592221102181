import React from 'react';
import { Link } from 'react-router-dom';
import ContentContainer from '../containers/ContentContainer';
import { Icon } from '@mdi/react';
import { mdiCodeTags, mdiSchool, mdiNewspaper, mdiUpdate, mdiRobot, mdiProjectorScreen } from '@mdi/js';
import DevSvg from '../assets/women-web-developer-with-laptop.svg';
import ElavateSvg from '../assets/speed-optimization.svg';
import '../styles/ContentContainer.css'
const DevTools = () => {
    return (
      <ContentContainer>
        <section className="section animated-fadeIn">
          <h1>Dev Tools</h1>
          <img src={DevSvg} alt="Women Web Developer" className="img-right"/>
          <p className='highlight-text-center' >Revolutionizing Your Development Process with AI-Driven Solutions</p>
          
        </section>
        <section className="section animated-fadeIn">
        <h1 style={{textAlign: 'left'}}>Features</h1>
          <h2><Icon path={mdiCodeTags} size={.8} style={{marginRight: '10px'}} className="list-icon" />AI-Driven Code Analysis </h2> 
            <p> Optimize and enhance your code with our advanced AI tools.</p>
          <h2><Icon path={mdiProjectorScreen} size={.8} style={{marginRight: '10px'}} className="list-icon" />Project Management </h2> 
            <p > Seamlessly start new projects or import existing ones with our integrated environment.</p>
          <h2><Icon path={mdiSchool} size={.8} style={{marginRight: '10px'}} className="list-icon" />Customized Learning </h2>
            <p> Share your skill level in different languages, and our AI will tailor explanations and guidance to accelerate your learning.</p>
          <h2><Icon path={mdiNewspaper} size={.8} style={{marginRight: '10px'}} className="list-icon" />Real-Time Development News </h2> 
            <p> Stay at the forefront of technology with AI-curated updates on modules, packages, and industry trends.</p>
          <h2><Icon path={mdiUpdate} size={.8} style={{marginRight: '10px'}} className="list-icon" />Proactive Suggestions </h2>
            <p> Receive AI-driven advice on adopting more efficient coding practices and newer, more effective technologies.</p>
          <h2><Icon path={mdiRobot} size={.8} style={{marginRight: '10px'}} className="list-icon" />Interactive AI Assistant </h2>
            <p> Engage with an AI expert for instant solutions and smart development strategies.</p> 
        </section>

        <section className="section animated-fadeIn">
          <h2>Ready to Elevate Your Development Experience?</h2>
          <img src={ElavateSvg} alt='Elevate' className='img-right'></img>
          <p>Sign up for our free account to get started with gavAI Dev Tools.</p>
          <p>We offer a wide range of AI-driven features to help you elevate your development experience.</p>
          <Link to="/sign-up" className="button-primary">Sign Up Now</Link>
        </section>
        <section className="section animated-fadeIn" style={{marginTop: '4.5rem'}}>
          <h2>VSCode Extension - Coming Soon</h2>
          <p>Integrate your entire project ecosystem with our upcoming VSCode extension for comprehensive AI analysis and insights.</p>
        </section>
      </ContentContainer>
    );
};

export default DevTools;