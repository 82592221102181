import React from 'react';
import { mdiCodeTags, mdiApi, mdiChatOutline, mdiWrench, mdiNewspaperVariant, mdiLinkVariant, mdiLockOutline, mdiHandshakeOutline, mdiHardHat } from '@mdi/js';
import Icon from '@mdi/react';
import '../styles/Pricing.css';
import ContentContainer from '../containers/ContentContainer'; // Import ContentContainer

const PricingPlans = () => {
    return (
        <ContentContainer>
            <div className="section animated-fadeIn">
              <h1>gavAI Pricing</h1>
                <div className="plan-details">
                    {/* Free Plan Details */}
                    <section className="free-plan-details section">
                        <h2>Free Plan - Explore AI Capabilities</h2>
                        <p>Our Free Plan is ideal for those starting out and wanting to explore the possibilities of AI. This plan includes:</p>
                            <p><Icon path={mdiChatOutline} size={1} className="list-icon" /> <span className="highlight">Customizable AI Chatmodel</span><span className="list-item-description">Experiment and build with our AI chatbot.</span></p>
                            <p><Icon path={mdiApi} size={1} className="list-icon" /> <span className="highlight">Own API Key Usage</span><span className="list-item-description">You must use your own API keys for AI models.</span></p>
                        <p className="price">Price: <span className="bold">Free</span></p>
                    </section>

                    {/* Individual Plan Details */}
                    <section className="individual-plan-details section">
                        <h2>Individual Plan - Full Feature Access</h2>
                        <p>The Individual Plan is perfect for personal projects or hobbyists looking to fully leverage AI capabilities. This plan includes:</p>
                        <ul className="icon-bullet-list">
                            <p><Icon path={mdiWrench} size={1} className="list-icon" /> <span className="highlight">Advanced AI Chatmodel</span><span className="list-item-description">Get access to a more robust and versatile chatbot that can perform actions and interact with APIs.</span></p>
                            <p><Icon path={mdiCodeTags} size={1} className="list-icon" /> <span className="highlight">Full-Scale App Development Tools</span><span className="list-item-description">Comprehensive tools to support your app development.</span></p>
                            <p><Icon path={mdiNewspaperVariant} size={1} className="list-icon" /> <span className="highlight">Tailored AI News</span><span className="list-item-description">Stay informed with AI-curated news tailored to your interests.</span></p>
                            <p><Icon path={mdiLinkVariant} size={1} className="list-icon" /> <span className="highlight">External API Integration</span><span className="list-item-description">Easily integrate with a variety of external APIs.</span></p>
                            <p><span className="highlight">Plus more innovative features on the horizon as we continually evolve and expand.</span></p>

                        </ul>
                        <p className="price">Price: <span className="bold">$60/month</span></p>
                    </section>

                    {/* Small Business Plan Details */}
                    <section className="small-business-plan-details section">
                        <h2>Small Business Plan - Custom Solutions & Support</h2>
                        <p>Our Small Business Plan is designed for businesses needing tailored solutions and dedicated support. This plan includes:</p>
                        <ul className="icon-bullet-list">
                            <p><Icon path={mdiHandshakeOutline} size={1} className="list-icon" /> <span className="highlight">Custom Solution Development</span><span className="list-item-description">Collaborate with our team for custom solutions.</span></p>
                            <p><Icon path={mdiHardHat} size={1} className="list-icon" /> <span className="highlight">Dedicated Support</span><span className="list-item-description">Priority support to ensure your business needs are met promptly.</span></p>
                            <p><Icon path={mdiLockOutline} size={1} className="list-icon" /> <span className="highlight">All Individual Plan Features</span><span className="list-item-description">Enjoy all the benefits of the Individual Plan.</span></p>
                        </ul>
                        <p className="price">Price:<a href="/contact" style={{marginLeft: "15px"}}className="primary-button">Contact</a> </p>
                        
                    </section>
                </div>
            </div>
        </ContentContainer>
    );
};

export default PricingPlans;
