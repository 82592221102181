import { mdiListBoxOutline,mdiDatabase, mdiHomeOutline, mdiAccountGroupOutline, mdiScaleBalance, mdiFormTextbox, mdiEarth,  mdiTools, mdiCubeScan,mdiClipboardListOutline, mdiViewDashboardOutline, mdiNewspaper, mdiConnection } from '@mdi/js';

export const iconMap = {
    'Landing Page': mdiHomeOutline,
    'About Us': mdiAccountGroupOutline,
    'Our Values': mdiScaleBalance,
    'Your Data': mdiDatabase,
    'Contact Form': mdiFormTextbox,
    'Externals': mdiEarth,
    'Tailored News': mdiNewspaper,
    'Integrations': mdiConnection,
    'Dev Tools': mdiTools,
    'Customizable AI Models': mdiCubeScan,
    'Plans': mdiClipboardListOutline,
    'Overview': mdiViewDashboardOutline,
    'Waitlist Form': mdiListBoxOutline
  };