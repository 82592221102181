import React from 'react';
import { Link } from 'react-router-dom';
import ContentContainer from '../containers/ContentContainer';
import '../styles/ContentContainer.css'; // Ensure this is the path to your CSS file
import BreakFreeSvg from '../assets/breakfree.svg'; // Make sure the path matches the location of your SVG
import PersonCreatingSvg from '../assets/man-browsing.svg'; // Make sure the path matches the location of your SVG

const AboutPage = () => {
  return (
    <ContentContainer>
      <section id="about" className="section animated-fadeIn">
        <h1>Welcome to the Nexus of Innovation</h1>
        <p className="highlight-text-center">Step into the world of gavAI, a beacon of innovation where artificial intelligence is seamlessly woven into the fabric of everyday life. Our mission is to harness the power of technology to amplify human potential and kindle the flames of passion and creativity.</p>
        <div className="section-list">
          <div>
            <h2>Our Foundation</h2>
            <img src={PersonCreatingSvg} alt="Our Foundation" className="img-right" />
            <p>gavAI stands as a testament to what can be achieved when imagination meets the rigor of technology. Birthed from a vision to revolutionize, our company is a sanctuary for forward-thinkers and a launchpad for transformative ideas that promise a future brimming with intention and opportunity.</p>
            <Link to="/about/us" className="button-primary">Dive deeper into our foundations</Link>
          </div>
        </div>
        <div className="section-text">
          <div>
            <h2>Our Principles</h2>
            <img src={BreakFreeSvg} alt="Our Principles" className="img-right" />
            <p>At gavAI, we envision AI as a catalyst for growth, not contention—forging tools that augment human endeavor rather than replace it. By leveraging intelligent technology, we aim to break the chains that bind people to monotonous jobs, freeing them to pursue their true passions.</p>
            <Link to="/about/values" className="button-primary">Explore our core principles</Link>
            <div>
            <p className="highlight-text">Our commitment to ethical innovation and the advancement of society stands at the core of every stride we take, guiding us toward a future where work is a choice inspired by passion, not a necessity dictated by circumstance.</p>
            </div>
          </div>
        </div>

        {/* Consider adding interactive elements or client testimonials to further enhance your brand story */}
      </section>
      {/* You can add more content and sections as needed */}
    </ContentContainer>
  );
};

export default AboutPage;
