import React from 'react';
import { Link } from 'react-router-dom';
import ContentContainer from '../containers/ContentContainer';
import '../styles/ContentContainer.css';
import SadPeopleSvg from '../assets/website-loading-speed.svg'; // This is the SVG showing people on a bench
import StartupSVG from '../assets/startup.svg'; // This is the SVG showing a startup'
import PeopleSvg from '../assets/people-using-technology.svg'; // This is the SVG showing people
import HackerSvg from '../assets/website-antivirus.svg'; // This is the SVG showing a hacker
const LandingPage = () => {
  return (
    <ContentContainer>
      <section className="section animated-fadeIn" style={{textAlign: 'center'}}>
        <h1>Welcome to gavAI</h1>
        <p className="highlight-text-center">
          Step into a world where AI empowers your daily life. At gavAI, we tailor technology to 
          resonate with your personal journey, making each day smoother, more creative, and filled with possibility.
        </p>
        <Link to="/about" className="button-primary">Learn More About Us</Link>
      </section>
      <section className="section mission-statement animated-fadeIn">
      <img src={SadPeopleSvg} style={{marginTop: '40px'}}  alt="People on Bench" className="img-left" />
        <div>
          <h1 style={{textAlign: 'right'}}>Transforming the Future of Work</h1>

          <p className="highlight-text" style={{textAlign: 'right'}}>
            At gavAI, we believe in a future where AI doesn't just replace human jobs, 
            but replaces the need for jobs altogether. We envision a world where 
            people are free to pursue their passions and dreams, liberated from 
            the constraints of work they don't enjoy. Our AI-driven solutions 
            are designed to foster this new era, empowering everyone to live a 
            life of choice, creativity, and fulfillment.
          </p>
        </div>
      </section>
      <section className="section animated-fadeIn">
        <img src={StartupSVG} style={{marginTop: '40px'}}  className='img-right' alt='Startup'></img>
        <h2>Our Services</h2>
        <p>
          Uncover the tools that transform your ideas into reality. Whether you're igniting a passion project, 
          advancing a cherished hobby, or giving life to your small-scale venture, our AI-driven solutions 
          stand by your side, fostering growth, creativity, and innovation on a deeply personal level.
        </p>
        <Link to="/services" className="button-primary">Explore Services</Link>
      </section>

      <section className="section animated-fadeIn" style={{textAlign: 'right'}}>
        <img src={PeopleSvg} style={{marginTop: '40px'}}  className='img-left' alt='People'></img>
        <h2>Pricing Plans</h2>
        <p>
          Accessible, fair, and designed for all walks of life – our pricing ensures that no matter where you 
          stand, be it as an individual dreamer or a small-scale entrepreneur, you have the tools to thrive.
        </p>
        <Link to="/pricing" className="button-primary">View Pricing</Link>
      </section>

      <section className="section animated-fadeIn">
        <img src={HackerSvg} style={{marginTop: '40px'}} className='img-right' alt='Hacker'></img>'
        <h2>Commitment to Privacy and Security</h2>
        <p>
          In a digital world, your privacy is your sanctuary. We stand guard, ensuring that every personal 
          story and entrepreneurial spirit under our wing remains secure and respected.
        </p>
        <Link to="/privacy" className="button-primary">Read Our Privacy Policy</Link>
      </section>

      <section className="section animated-fadeIn" style={{textAlign: 'right'}}>
        <h2>Contact Us</h2>
        <p>
          Whether you're seeking answers, guidance, or just want to share your story, we're here. 
          Reach out and let's make your journey extraordinary, together.
        </p>
        <Link to="/contact" className="button-primary">Get in Touch</Link>
      </section>
      <section className="section animated-fadeIn">
        <h2>Join Our Waitlist</h2>
        <p>
          Excited about what's coming? Sign up for our waitlist to be notified when our services are available.
        </p>
        <Link to="/waitlist" className="button-primary">Join the Waitlist</Link>
        </section>
    </ContentContainer>
  );
};

export default LandingPage;
