// src/contexts/HeaderContext.js

import { createContext, useContext, useState, useCallback } from 'react';

const HeaderContext = createContext();

export const useHeaderContext = () => useContext(HeaderContext);

export const HeaderProvider = ({ children }) => {
    const [activeItem, setActiveItem] = useState('/'); // Default active item
  
    const memoizedSetActiveItem = useCallback(setActiveItem, []);
  
    return (
      <HeaderContext.Provider value={{ activeItem, setActiveItem: memoizedSetActiveItem }}>
        {children}
      </HeaderContext.Provider>
    );
  };
  
