export const globalSections = {
    home: ['Landing Page'],
    services: ['Tailored News', 'Dev Tools', 'Integrations','Customizable AI Models'],
    pricing: ['Plans'],
    about: ['About Us', 'Our Values'],
    privacy: ['Your Data'],
    contact: ['Contact Form'],
    waitlist: ['Waitlist Form']
  };

export const globalMobileSections = {
    home: ['Landing Page'],
    services: ['Overview', 'Tailored News', 'Dev Tools', 'Integrations', 'Customizable AI Models'],
    pricing: ['Overview', 'Plans'],
    about: ['Overview', 'About Us', 'Our Values'],
    privacy: ['Overview', 'Your Data'],
    contact: ['Contact Form'],
    waitlist: ['Waitlist Form']
  };
