// src/contexts/MenuContext.js
import React, { createContext, useContext, useState } from 'react'; // Add useState here
import { globalSections, globalMobileSections } from '../data/globalSections';
import { isMobile } from '../utils/device';

const MenuContext = createContext();

export const useMenu = () => useContext(MenuContext);

export const MenuProvider = ({ children }) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false); // Correctly imported useState

  const toggleMenu = () => {
    setIsMenuOpen(prev => !prev); // Use functional update to toggle based on previous state
    const body = document.body;
    if (!isMenuOpen) {
      body.classList.add('menu-open');
    } else {
      body.classList.remove('menu-open');
    }
  };

  // Use mobile or desktop sections based on the device type
  const sections = isMobile() ? globalMobileSections : globalSections;

  // Include isMenuOpen and toggleMenu in the context value
  const value = {
    sections,
    isMenuOpen,
    toggleMenu,
  };

  return (
    <MenuContext.Provider value={value}>
      {children}
    </MenuContext.Provider>
  );
};

export default MenuProvider;
