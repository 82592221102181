import React from 'react';
import ContentContainer from '../containers/ContentContainer';
import ChatmodelSvg from '../assets/person-creating.svg'; // Placeholder for your SVG asset
import '../styles/ContentContainer.css';

const CustomizableAIChatmodel = () => {
    return (
      <ContentContainer>
        <section className="section animated-fadeIn">
          <h1>Personal AI Assistant</h1>
          <img src={ChatmodelSvg} alt="Personal AI Assistant" className="img-right" />
          <p className="highlight-text-center">Empower Your Curiosity and Creativity</p>
        </section>

        <section className="section section-list animated-fadeIn">
          <h2>Your Learning and Creating Companion</h2>
          <p>
            Discover the dynamic capabilities of your personal AI assistant with gavAI. While the adaptability feature, which evolves the AI chatmodel based on your past conversations, is completely optional and disabled by default, it offers a unique opportunity to enhance your experience. By visiting the profile customizer tab in the gavAI dashboard, you can choose to enable this feature, allowing the model to learn from past interactions for a more tailored and engaging experience.
          </p>
          <p>
            <a href="/privacy/your-data" className="button-primary">Learn More about what data is used</a>
          </p>
          <p className="highlight-text-center" style={{fontSize: '15px'}}>
            Remember, the choice is entirely yours. You control how much or how little information is stored, ensuring your comfort and privacy are always respected.
          </p>
          <h1>Available Models in gavAI</h1>
            <h2 className="highlight-text-list">AWS Bedrock</h2>
            <p className='list-item-description'>Explore a diverse range of topics with models from AI21 Labs, Meta, Cohere, and Anthropic through AWS Bedrock.</p>
            <h2 className="highlight-text-list">OpenAI</h2>
            <p className='list-item-description'>Engage with models like GPT-4 for deep insights and creative problem-solving.</p>

            <h2 className="highlight-text-list">Hugging Face</h2>
            <p className='list-item-description'>Get creative with a wide selection of trending custom models.</p>
          <h2>Flexible Cost Structure</h2>
          <p>
            The costs for using these AI models through us are included in your monthly subscription.
          </p>

          <h2>Advanced Fine-Tuning</h2>
          <p>Customize AI models to align perfectly with your personal projects or learning objectives, offering a uniquely tailored experience.</p>
          
          <h2>Embedding Model Integration</h2>
          <p>Enhance your chatmodel with the latest embeddings, ensuring accurate, context-rich interactions for all your inquiries and projects.</p>
          
          <h2>Empowering Individuality</h2>
          <p>Your AI assistant is more than a tool; it's a partner in your journey of learning, creating, and exploring. From information on fixing a car to building your next big project, the possibilities are endless.</p>
        </section>
      </ContentContainer>
    );
};

export default CustomizableAIChatmodel;
