// src/components/AnimatedLogo.js
import React, { useEffect, useRef } from 'react';
import { animateLogo } from '../utils/gsapLogoAnimations';
import { ReactComponent as LogoSVG } from '../logo.svg'; // Assuming create-react-app's SVG support
import '../styles/Logo.css';

const AnimatedLogo = () => {
  const logoRef = useRef(null);
  
  useEffect(() => {
    let timeline = null;
    if (logoRef.current) {
      // animateLogo function is called only once after the component mounts
      timeline = animateLogo(logoRef.current);
    }
    // Cleanup function to kill the timeline animations
    return () => {
      if (timeline) {
        timeline.kill();
      }
    };
  }, []); // Empty dependency array ensures this effect only runs once
  
  return (
    <div className='logo-container' ref={logoRef}>
      <LogoSVG />
    </div>
  );
};

export default AnimatedLogo;
