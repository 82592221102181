// src/components/HeaderMenu.js
import React from 'react';
import { Link } from 'react-router-dom';
import { useMenu } from '../contexts/MenuContext';
import { iconMap } from '../data/globalIcons';
import Icon from '@mdi/react';
import { generatePath } from '../data/redirectionMap'; // Make sure to import the generatePath function
import '../styles/HeaderMenu.css';

const HeaderMenu = ({ section, className }) => {
  const { sections } = useMenu();
  const menuItems = sections[section] || []; // Access the sections for the given page

  return (
    <div className={className}>
      {menuItems.map((item, index) => {
        const icon = iconMap[item] || null; // Fallback to null if no icon is found
        const path = generatePath(section, item); // Use the generatePath function to get the correct path

        return (
          <div key={index} className="header-menu-item">
            <Link to={path} className="header-menu-link" onClick={(e) => e.stopPropagation()}>
              {icon && <Icon path={icon} size={1} />} {/* Display the icon */}
              {item}
            </Link>
          </div>
        );
      })}
    </div>
  );
};

export default HeaderMenu;
