import React, { useState, useEffect } from 'react';
import ContentContainer from '../containers/ContentContainer';

import '../styles/Contact.css';

const ContactPage = () => {
  const [formData, setFormData] = useState({ name: '', email: '', message: '' });
  const [captchaToken, setCaptchaToken] = useState('');
  const [showCaptcha, setShowCaptcha] = useState(false);
  const [isCaptchaScriptLoaded, setIsCaptchaScriptLoaded] = useState(false);

  useEffect(() => {
    if (!document.querySelector('script[src="https://b7405edef359.edge.captcha-sdk.awswaf.com/b7405edef359/jsapi.js"]')) {
      const script = document.createElement('script');
      script.src = 'https://b7405edef359.edge.captcha-sdk.awswaf.com/b7405edef359/jsapi.js';
      script.defer = true;
      script.onload = () => setIsCaptchaScriptLoaded(true);
      document.body.appendChild(script);
    } else {
      setIsCaptchaScriptLoaded(true);
    }
  }, []);

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
    autoResizeTextArea(e.target);
  };

  const autoResizeTextArea = (element) => {
    element.style.height = 'auto';
    element.style.height = element.scrollHeight + 'px';
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (formData.name && formData.email && formData.message) {
      setShowCaptcha(true);
    } else {
      alert('Please fill all the fields.');
    }
  };
  
  const handleCaptchaVerify = async (token) => {
    setCaptchaToken(token);
  
    // Construct the request body in the required format
    const requestBody = {
      httpMethod: "POST",
      body: JSON.stringify(formData)
    };
  
    try {
      const response = await fetch('https://22alawmxu2.execute-api.us-east-1.amazonaws.com/prod/contact-form', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${process.env.REACT_APP_API_KEY}` // Use your API key here
        },
        body: JSON.stringify(requestBody), // Send the modified request body
      });
  
      if (response.ok) {
        alert('Form submitted successfully!');
        setFormData({ name: '', email: '', message: '' });
        setCaptchaToken('');
        setShowCaptcha(false);
      } else {
        const errorText = await response.text();
        alert(`There was an issue submitting the form: ${errorText}`);
      }
    } catch (error) {
      alert(`Error submitting the form: ${error.message}`);
    }
  };
  
  
  const renderCaptcha = () => {
    if (window.AwsWafCaptcha && document.getElementById('captcha-container')) {
      window.AwsWafCaptcha.renderCaptcha(document.getElementById('captcha-container'), {
        apiKey: process.env.REACT_APP_CAPTCHA_API_KEY, // Replace with your actual API key
        onSuccess: handleCaptchaVerify,
        onError: (error) => console.error("Captcha Error:", error),
        // Add other configuration parameters as needed
      });
    }
  };
  
  const Captcha = () => {
    useEffect(() => {
      // Assuming renderCaptcha is defined as shown above
      renderCaptcha();
    }, []);
  
    return <div id="captcha-container"></div>;
  };
  return (
    <ContentContainer>
      <section className="contact-section">
        <h1>Contact Us</h1>
        <div className="contact-container">
          <form className="contact-form" onSubmit={handleSubmit}>
            {/* Form Fields */}
            <label htmlFor="name">Name</label>
            <input
              type="text"
              id="name"
              name="name"
              value={formData.name}
              onChange={handleChange}
              required
              placeholder="Your Full Name" // Example placeholder text
            />

            <label htmlFor="email">Email</label>
            <input
              type="email"
              id="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              required
              placeholder="example@email.com" // Example placeholder text
            />

            <label htmlFor="message">Message</label>
            <textarea
              id="message"
              name="message"
              value={formData.message}
              onChange={handleChange}
              required
              placeholder="Your message or inquiry here..." // Example placeholder text
            />

            {/* Captcha Component */}
            {showCaptcha && isCaptchaScriptLoaded && <Captcha onVerify={handleCaptchaVerify} />}
            <button type="submit">Send Message</button>
          </form>

          {/* Contact Info */}
          <div className="contact-info">
            <h2>Our Only Contact Info</h2>
            <p>Email: contact@gavai.io</p>
            <p>Phone: (480) 370-6283</p>
          </div>
        </div>
      </section>
    </ContentContainer>
  );
};

export default ContactPage;