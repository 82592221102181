import React from 'react';
import ContentContainer from '../containers/ContentContainer';
import AntivirusFightWithHackersSvg from '../assets/antivirus-fight-with-hackers.svg'; // Import the SVG
import { Link } from 'react-router-dom';
const Privacy = () => {
    return (
        <ContentContainer>

          <div className="section fade-in">
              <h2 className="highlight-text">Our Commitment to Privacy</h2>
              <img src={AntivirusFightWithHackersSvg} alt="Protecting Data" className="img-right"/>
              <p>
                  At gavAI, we deeply respect your right to privacy. Our platform is meticulously crafted to function with only the essential data required for account setup. Should you choose to personalize your experience further, you can selectively share your interests, but this step remains entirely optional. 
              </p>
              <p>
                  We pledge to uphold the confidentiality of your data, firmly resisting any external corporate interests seeking to profit from it. We stand as guardians of your information, fortified with cutting-edge encryption technology to secure your data against any intrusion.
              </p>
          </div>
          <div className="section">
            <p> 
                We believe that your data is yours alone, and our unwavering commitment to protecting 
                it is at the core of everything we do. We have a firm policy: we will never sell your 
                data to any third parties, nor will we use it for external advertising purposes.
            </p>

            <h2>No Data Collection Without Consent</h2>
            <p>
                We do not collect any personal data about our users unless explicitly provided 
                to us in the Customize Profile section of our dashboard. This collection is 
                entirely optional and is used solely to enhance your user experience.
            </p>

            <h2>Your Data, Your Rules</h2>
            <p>
                Any data you choose to share with us is treated with the utmost care and 
                confidentiality. We use state-of-the-art security measures to ensure that 
                your information is protected at all times. Our team is committed to upholding 
                the highest standards of privacy and data protection.
            </p>

            <h2>Transparency and Control Over Advertising</h2>
            <p>
                We pledge to never use your data to push external advertisements. Instead, we believe 
                in supporting and promoting projects created with gavAI. This serves as our way of 
                thanking our community for their support and showcasing their achievements. However, 
                this is done with respect for your choices and privacy. If you prefer not to see these 
                curated project feeds, you can easily opt out at any time in your settings, ensuring 
                that your experience with us is tailored to your preferences.
            </p>

            <h2>Our Stance Against Invasive Practices</h2>
            <p>
                In today’s digital landscape, many companies engage in practices that compromise 
                user privacy. We stand firmly against such practices. We believe in building 
                trust with our users through honesty and integrity, not through the exploitation 
                of personal information. Your private life is none of our business, and we strive 
                to keep it that way.
            </p>

            <p>
                If you have any questions or concerns about our privacy practices or advertising policies, 
                please feel free to contact us. We are more than happy to provide further information and 
                reassurances about how we protect your privacy and respect your choices.
            </p>
            <section className='section' style={{ textAlign: 'right' }}>
                <p className='highlight'>
                    Delve into our detailed guidelines for protecting and managing your data.
                </p>
                <Link to="/privacy/your-data" className="button-primary">
                    Learn About Data Security
                </Link>
            </section>


          </div>
        </ContentContainer>
    );
};

export default Privacy;
