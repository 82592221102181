import React from 'react';
import { mdiCreditCardOffOutline, mdiCodeTags, mdiApi, mdiChatOutline, mdiWrench, mdiNewspaperVariant, mdiLinkVariant, mdiLockOutline, mdiAccountGroupOutline, mdiHandshakeOutline, mdiAccountOutline, mdiHardHat } from '@mdi/js';
import Icon from '@mdi/react';
import '../styles/Pricing.css';
import '../styles/ContentContainer.css';
const PricingContainer = () => {
    return (
        <div className="pricing-container animated-fadeIn">
            <div className="plan-container">
                {/* Free Plan Section */}
                <section className="free-plan">
                    <Icon path={mdiCreditCardOffOutline} size={1} />
                    <h2>Free Plan</h2>
                    <p><span className="highlight">Explore AI capabilities</span> with our customizable Chatmodel.</p>
                    <ul>
                        <li><Icon path={mdiChatOutline} size={0.8} /> Customizable AI Chatmodel</li>
                        <li><Icon path={mdiApi} size={0.8} /> Must use own API keys for models</li>
                    </ul>
                    <p className="price">Price: <span className="bold">Free</span></p>
                    <a className="button-primary" href="/pricing/plans">view plan</a>
                </section>

                {/* Individual Plan Section */}
                <section className="individual-plan">
                    <Icon path={mdiAccountOutline} size={1} />
                    <h2>Individual Plan</h2>
                    <p><span className="highlight">Unlock full features</span> for personal or hobbyist projects.</p>
                    <ul>
                        <li><Icon path={mdiWrench} size={0.8} /> Advanced AI Chatmodel</li>
                        <li><Icon path={mdiCodeTags} size={0.8} /> Full-Scale App Development Tools</li>
                        <li><Icon path={mdiNewspaperVariant} size={0.8} /> Tailored AI News</li>
                        <li><Icon path={mdiLinkVariant} size={0.8} /> External API Integration Flexibility</li>
                    </ul>
                    <p className="price">Price: <span className="bold">$60/month</span></p>
                    <a className="button-primary" href="/pricing/plans">view plan</a>
                </section>
                {/* Small Business Plan Section */}
                <section className="small-business-plan">
                    <Icon path={mdiAccountGroupOutline} size={1} />
                    <h2>Small Business Plan</h2>
                    <p><span className="highlight">Custom solutions and dedicated support</span> for your business.</p>
                    <ul>
                        <li><Icon path={mdiHandshakeOutline} size={0.8} /> Custom Solution Development</li>
                        <li><Icon path={mdiHardHat} size={0.8} /> Dedicated Support</li>
                        <li><Icon path={mdiLockOutline} size={0.8} /> All Individual Plan Features</li>
                    </ul>
                    <p className="price">Price: <span className="bold">Custom Pricing</span></p>
                    <a className="button-primary" href="/pricing/plans">view plan</a>
                </section>
            </div>
        </div>
    );
};

export default PricingContainer;
