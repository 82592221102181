// src/components/MobileHeader.js
import React, { useState, useEffect } from 'react';
import MobileHeaderMenu from './MobileHeaderMenu';
import Icon from '@mdi/react';
import { mdiMenu, mdiClose } from '@mdi/js';
import '../styles/MobileHeader.css';
import MobileAnimatedLogo from './MobileAnimatedLogo';
import { useMenu } from '../contexts/MenuContext';
import { isIphone } from '../utils/device'; // Import the isIphone function
import logoPng from '../assets/gavai_dark.png'; // Import the PNG image directly
import '../styles/MobileLogo.css';

const MobileHeader = () => {
    const { isMenuOpen, toggleMenu } = useMenu();
    const [isSubMenuOpen, setIsSubMenuOpen] = useState(false);
    const [lastScrollY, setLastScrollY] = useState(0);
    const [headerVisible, setHeaderVisible] = useState(true);
  
    const controlHeaderVisibility = () => {
      if (typeof window !== 'undefined') {
        setHeaderVisible(window.scrollY < lastScrollY || window.scrollY <= 80);
        setLastScrollY(window.scrollY);
      }
    };
  
    useEffect(() => {
      if (typeof window !== 'undefined') {
        window.addEventListener('scroll', controlHeaderVisibility);
        return () => {
          window.removeEventListener('scroll', controlHeaderVisibility);
        };
      }
    }, [lastScrollY]);
    
    const handleMenuClick = () => {
        toggleMenu();
    };
    
    const toggleSubMenu = () => {
        setIsSubMenuOpen(!isSubMenuOpen);
    };

    // Here you conditionally render the PNG for iPhone and SVG for other devices
    const renderLogo = () => {
        if (isIphone()) {
            // Use the imported PNG image directly in an img tag
            return (
                <div className='logo-container-iphone'>
                    <img src={logoPng} alt="Logo" />
                </div>
            );
        } else {
            return <MobileAnimatedLogo />;
        }
    };

    return (
        <header className={`mobile-header ${isMenuOpen ? 'mobile-header-expanded' : ''} ${headerVisible ? '' : 'mobile-header-hidden'}`}>
            {renderLogo()}
            <div className={`menu-icon ${isMenuOpen ? 'active' : ''}`} onClick={handleMenuClick}>
                <Icon path={isMenuOpen ? mdiClose : mdiMenu} size={1} />
                <span />
                <span />
                <span />
            </div>
            <MobileHeaderMenu isOpen={isMenuOpen} onClose={toggleSubMenu}/>
        </header>
    );
};

export default MobileHeader;
