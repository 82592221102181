// Footer.js

import React from 'react';
import '../styles/Footer.css'; // Importing the CSS file for styling

const Footer = () => {
  return (
    <footer className="footer">
      <div className="footer-content">
        <p>© {new Date().getFullYear()} gavAI - All rights reserved.</p>
        <p>
          Icons by <a href="https://iconscout.com/contributors/woobrodesign" target="_blank" rel="noopener noreferrer">Woobro Design</a>, licensed under <a href="https://creativecommons.org/licenses/by/4.0/" target="_blank" rel="noopener noreferrer">CC BY 4.0</a>.
        </p>
      </div>
    </footer>
  );
};

export default Footer;
