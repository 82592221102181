// src/components/MobileHeaderMenu.js
import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import Icon from '@mdi/react';
import { mdiChevronDown, mdiChevronUp } from '@mdi/js'; // Icons for submenu indicators
import '../styles/MobileHeader.css'; // Your CSS file
import { iconMap } from '../data/globalIcons';
import { useMenu } from '../contexts/MenuContext';
import {
  mdiHomeOutline,
  mdiWrenchOutline,
  mdiTagOutline,
  mdiInformationOutline,
  mdiShieldOutline,
  mdiEmailOutline,
  mdiListBoxOutline
} from '@mdi/js'; // Import additional icons
import { generatePath } from '../data/redirectionMap';
const sectionIcons = {
    home: mdiHomeOutline,
    services: mdiWrenchOutline,
    pricing: mdiTagOutline,
    about: mdiInformationOutline,
    privacy: mdiShieldOutline,
    contact: mdiEmailOutline,
    waitlist: mdiListBoxOutline
  };
  
const MobileHeaderMenu = ({ isOpen, onClose }) => {
  const { sections } = useMenu();
  const [openSubMenus, setOpenSubMenus] = useState({});
  const location = useLocation();

  useEffect(() => {
    // Toggle the 'menu-open' class on the body depending on whether the menu is open
    if (isOpen) {
      document.body.classList.add('menu-open');
    } else {
      document.body.classList.remove('menu-open');
    }
  }, [isOpen]); // Depend on the 'isOpen' state

  // Close the menu when the location changes and the menu is open
  useEffect(() => {
    if (isOpen) {
      onClose(); // This will call the toggleMenu function passed from the parent to close the menu
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  // Function to handle submenu toggling
  const toggleSubMenu = (sectionName) => {
    setOpenSubMenus((prevState) => ({
      ...prevState,
      [sectionName]: !prevState[sectionName],
    }));
  };

  // Close the menu when the location changes and the menu is open
  useEffect(() => {
    if (isOpen) {
      onClose(); // This will call the toggleMenu function passed from the parent to close the menu
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  // Function to determine the redirect path
  const getRedirectPath = (section, item) => {
    return generatePath(section, item);
  };

  // Add a new function to handle link clicks
  const handleLinkClick = (section, item) => {
    const path = getRedirectPath(section, item);
    if (location.pathname !== path && isOpen) {
      onClose(); // This will call the toggleMenu function to close the whole menu
    }
    // No need to toggle the submenu since we are closing the whole menu
  };
  // Make sure sections is an object with the expected structure
  if (typeof sections !== 'object' || sections === null) {
    console.error('sections is not an object:', sections);
    return null; // Or some fallback UI
  }

  return (
    <div className={`mobile-header-menu ${isOpen ? 'open' : ''}`}>
      {Object.entries(sections).map(([section, items]) => {
        // Make sure items is an array
        if (!Array.isArray(items)) {
          console.error('Expected items to be an array in section:', section, 'but got:', items);
          return null; // Or some fallback UI for this section
        }

        // Use the icon from the sectionIcons map
        const sectionIcon = sectionIcons[section.toLowerCase()];

        return (
          <div key={section}>
            <div className="menu-section-title" onClick={() => toggleSubMenu(section)}>
              {sectionIcon && <Icon path={sectionIcon} size={1} className='mdi-icon-left' />} {/* Display the icon */}
              {section}
              <Icon path={openSubMenus[section] ? mdiChevronUp : mdiChevronDown} size={1} className='mdi-icon-right' />
            </div>
            <div className={`submenu-container ${openSubMenus[section] ? 'submenu-open' : ''}`}>
            {items.map((item, index) => (
              <Link
                  key={index}
                  to={getRedirectPath(section, item)} // Use the updated getRedirectPath function
                  className="mobile-header-menu-item"
                  onClick={() => handleLinkClick(section, item)}
              >
                  <Icon path={iconMap[item]} className="mobile-menu-item-icon" size={1} />
                  {item}
              </Link>
              ))}
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default MobileHeaderMenu;