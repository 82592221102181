// src/components/ConditionalBlurOverlay.js
import React from 'react';
import { useMenu } from '../contexts/MenuContext';
import BlurOverlay from './BlurOverlay'; // Adjust the import path as necessary

const ConditionalBlurOverlay = () => {
  const { isMenuOpen } = useMenu(); // Use the context to get the state of the menu
  return isMenuOpen ? <BlurOverlay /> : null;
};

export default ConditionalBlurOverlay;
