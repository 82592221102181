import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Header from './components/Header';
import MobileHeader from './components/MobileHeader';
import LandingPage from './pages/LandingPage';
import AboutPage from './pages/AboutPage';
import PrivacyPage from './pages/PrivacyPage';
import ContactPage from './pages/ContactPage';
import PricingPage from './pages/PricingPage';
import PrivacyDataPage from './pages/PrivacyDataPage';
import ServicesPage from './pages/ServicesPage';
import './styles/App.css';
import { HeaderProvider } from './contexts/HeaderContext';
import { MenuProvider } from './contexts/MenuContext';
import { WarpProvider } from './contexts/WarpContext';
import StarBackground from './components/StarBackground';
import { isMobile } from './utils/device';
import ConditionalBlurOverlay from './components/ConditionalBlurOverlay'; // Import the new component
import AboutValues from './pages/AboutValues';
import AboutUs from './pages/AboutUs';
import DevTools from './pages/DevTools';
import TailoredNews from './pages/TailoredNews';
import Integrations from './pages/Integrations';
import CustomizableAIChatmodel from './pages/CustomModels';
import PricingPlans from './pages/PricingPlans';
import IntelligentThreatProtection from './components/IntelligentThreatProtection';
import Footer from './components/Footer';
import WaitlistPage from './pages/WaitlistPage'
function App() {
  return (
    <IntelligentThreatProtection>
      <Router>
        <WarpProvider>
          <HeaderProvider>
            <MenuProvider>
              {isMobile() ? <MobileHeader /> : <Header />}
              {isMobile() && <ConditionalBlurOverlay />} 
              <div>
                <StarBackground />
                <Routes>
                  <Route path="/" element={<LandingPage />} />
                  <Route path="/about" element={<AboutPage />} />
                  <Route path="/about/us" element={<AboutUs />} />
                  <Route path="/about/values" element={<AboutValues />} />
                  <Route path="/contact" element={<ContactPage />} />
                  <Route path="/privacy" element={<PrivacyPage />} />
                  <Route path="/privacy/your-data" element={<PrivacyDataPage />} />
                  <Route path="/services" element={<ServicesPage />} />
                  <Route path="/services/dev-tools" element={<DevTools />} />
                  <Route path="/services/tailored-news" element={<TailoredNews />} />
                  <Route path="/services/integrations" element={<Integrations />} />
                  <Route path="/services/models" element={<CustomizableAIChatmodel />} />
                  <Route path="/pricing" element={<PricingPage />} />
                  <Route path="/pricing/plans" element={<PricingPlans />} />
                  <Route path="/waitlist" element={<WaitlistPage />} />
                </Routes>
              </div>
            <Footer />
            </MenuProvider>
          </HeaderProvider>
        </WarpProvider>
      </Router>
    </IntelligentThreatProtection>
  );
}

export default App;
