// WarpContext.js
import React, { createContext, useState, useCallback } from 'react';

export const WarpContext = createContext({
  isWarp: false,
  handleWarp: () => {},
});

export const WarpProvider = ({ children }) => {
    const [isWarp, setIsWarp] = useState(false);
  
    const handleWarp = useCallback(() => {
      if (!isWarp) {
        setIsWarp(true);
        setTimeout(() => setIsWarp(false), 1000);
      }
    }, [isWarp]);
  
    return (
      <WarpContext.Provider value={{ isWarp, handleWarp }}>
        {children}
      </WarpContext.Provider>
    );
  };