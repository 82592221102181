// src/utils/redirectionMap.js

// Define a redirection map that correlates the section and its items to paths
export const redirectionMap = {
    home: {
      'Landing-Page': '/',

    },
    services: {
      Overview: '/services', 
      'Dev-Tools': '/services/dev-tools',
      'Tailored-News': '/services/tailored-news',
      'Integrations': '/services/integrations',
      'Customizable-AI-Models': '/services/models',
    },
    pricing: {
      Overview: '/pricing',
      Plans: '/pricing/plans',
    },
    about: {
      Overview: '/about',
      'About-Us': '/about/us',
      'Our-Values': '/about/values',
    },
    privacy: {
      'Your-Data': '/privacy/your-data',
    },
    contact: {
      'Contact-Form': '/contact',
    },
    waitlist: {
      'Waitlist-Form': '/waitlist'
    }
  };
  
  // A utility function to generate a path for a given section and item
  export const generatePath = (section, item) => {
    // Normalize the section to lowercase to match the keys in the map
    const normalizedSection = section.toLowerCase();
    const normalizedItem = item.replace(/\s+/g, '-'); // Replace spaces with dashes for the URL
    // Access the redirection map for the path, or default to '/' if not found
    return redirectionMap[normalizedSection][normalizedItem] || '/';
  };
  