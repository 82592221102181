import React from 'react';
import { isMobile } from '../utils/device';
import '../styles/ContentContainer.css';

const ContentContainer = ({ children }) => {
  const mobile = isMobile();
  const containerClass = mobile ? 'mobile-content-container animated-fadeIn' : 'content-container animated-fadeIn';

  return (
    <div className={containerClass}>
      {children}
    </div>
  );
};

export default ContentContainer;
