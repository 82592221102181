import React from 'react';
import ContentContainer from '../containers/ContentContainer';
import { mdiShieldCheckOutline, mdiFileDownloadOutline, mdiDeleteOutline, mdiCellphoneMessage, mdiKeyVariant, mdiAlertCircleOutline, mdiCreditCardOffOutline, mdiCloudLockOutline } from '@mdi/js';
import Icon from '@mdi/react';
import '../styles/ContentContainer.css'; // Import CSS for styling

const PrivacyDataPage = () => {
    return (
        <ContentContainer>
            <div className="section fade-in">
                <h1>Privacy and Data Usage at gavAI</h1>
                <p>
                    At gavAI, your privacy is our utmost priority. Our service requires only 
                    basic signup information (email and phone number(Only if SMS 2FA is used)) to function. Any additional data you choose 
                    to share is entirely optional.
                </p>

                <h2 className="highlight-text">Optional Data for Enhanced Experiences</h2>
                <p>
                    To enhance your experience, you can opt to share more data with us. This additional 
                    information allows us to tailor our services according to your interests. However, 
                    remember that this is entirely your choice, and not a requirement.
                </p>

                <h2 className="highlight-text">Session Only Storage: Prioritizing Your Privacy</h2>
                <p>
                    For those who prefer not to store their data long-term, we offer 'Session Only Storage.' This innovative feature includes:
                    <ul className="icon-bullet-list">
                        <li><Icon path={mdiShieldCheckOutline} size={0.8} /> Optional verification through MFA or SMS 2FA before data download, for added security.</li>
                        <li><Icon path={mdiFileDownloadOutline} size={0.8} /> Download a JSON file of your interaction data and preferences.</li>
                        <li><Icon path={mdiDeleteOutline} size={0.8} /> We then wipe your data (excluding essential login information) from our servers.</li>
                        <li><Icon path={mdiCloudLockOutline} size={0.8} /> Upon your next login, upload this file to restore your personalized session settings.</li>
                    </ul>
                </p>

                <h2 className="highlight-text">User-Controlled Data Deletion</h2>
                <p>
                    At any time, you have the right to delete your data from our servers. We have a zero-day 
                    retention policy for deleted data. We recommend downloading your data before deletion, 
                    in case you change your mind. This step ensures that you have control over your data 
                    while maintaining your privacy.
                </p>

                <h2 className="highlight-text">Respectful Communication Practices</h2>
                <p>
                    We value your peace of mind and will never send unsolicited emails or texts. The only scenarios where we may contact you are:
                    <ul className="icon-bullet-list">
                        <li><Icon path={mdiCellphoneMessage} size={0.8} /> SMS or Email authentication.</li>
                        <li><Icon path={mdiKeyVariant} size={0.8} /> One-time password delivery.</li>
                        <li><Icon path={mdiAlertCircleOutline} size={0.8} /> If we detect suspicious activities that may compromise your account.</li>
                    </ul>
                </p>

                <h2 className="highlight-text">No Credit Card Information Stored</h2>
                <p>
                    To ensure the security of your financial information, we do not store credit card details.
                    <ul className="icon-bullet-list">
                        <li><Icon path={mdiCreditCardOffOutline} size={0.8} /> You will need to re-enter your payment information each time you renew or make a purchase.</li>
                    </ul>
                </p>
                <h2 className="highlight-text">Security and Hosting</h2>
                <p>
                    Hosted on Amazon Web Services (AWS), we ensure robust security and privacy standards. 
                    Our strict access policies safeguard your personal data, ensuring a secure and reliable 
                    service.
                </p>

                <p>
                    We are committed to maintaining transparency and respect for your privacy. Should you have 
                    any questions about our data practices, please feel free to contact us.
                </p>
            </div>
        </ContentContainer>
    );
};

export default PrivacyDataPage;
