import { gsap } from 'gsap';

export const animateLogo = (isFirstLoad) => {
    const totalDuration = 1; // Total duration of 1 second
    const pathsArray = gsap.utils.toArray('#Layer_7 path, #Layer_8 path, #Layer_9 path, #Layer_10 path, #Layer_3 path, #Layer_4 path, #Layer_5 path, #Layer_6 path, #Layer_2 path');
    const speed = totalDuration / pathsArray.length; // Adjust speed so the total duration is still 1 second
    const staggerValue = speed * 0.3; // Stagger each animation to start 60% into the previous one
    
    // Create a gsap timeline with staggered start for each path animation
    const tl = gsap.timeline({ 
        defaults: { 
            ease: 'power3.inOut', 
            duration: speed 
        },
        stagger: staggerValue
    });
    const offsets = [
        { x: -100, y: -100 }, // Offset for Layer 7
        { x: 100, y: -100 },  // Offset for Layer 8
        { x: -150, y: 100 },  // Offset for Layer 9
        { x: 150, y: 100 },   // Offset for Layer 10
        { x: -100, y: 50 },   // Offset for Layer 3
        { x: 100, y: 50 },    // Offset for Layer 4
        { x: -50, y: -150 },  // Offset for Layer 5
        { x: 50, y: -150 },   // Offset for Layer 6
        { x: 0, y: 150 }      // Offset for Layer 2
    ];
    
    // Set each path to its offset and invisible state initially
    pathsArray.forEach((path, index) => {
      gsap.set(path, {
          transformOrigin: '50% 50%',
          scale: 0,
          x: offsets[index].x,
          y: offsets[index].y,
          opacity: 0
      });
  });

  // Animate each path back to the final state with opacity fading in last
  pathsArray.forEach((path) => {
      tl.to(path, {
          scale: 1,
          x: 0,
          y: 0,
      }, "-=" + staggerValue * 0.9) // Overlap the timing slightly to smooth the transition
        .to(path, {
          opacity: 1,
          duration: speed * 0.5 // Fade in faster than the other transformations
      }, "-=" + staggerValue); // Start fading in opacity earlier
  });
  
  return tl;
};