import React from 'react';
import ContentContainer from '../containers/ContentContainer';
import IntegrationSvg from '../assets/people-using-technology.svg'; // Placeholder for your SVG asset
import '../styles/ContentContainer.css';

const Integrations = () => {
    return (
      <ContentContainer>
        <section className="section animated-fadeIn">
          <h1>Seamless Integrations</h1>
          <img src={IntegrationSvg} alt="Seamless Integrations" className="img-right"/>
          <p className="highlight-text-center">Unify Your Digital Tools</p>
  
          <div className="section-text">
            <h2>Boundless Integration Possibilities</h2>
            <p>Discover the power of connectivity Integrations. Our platform allows you to seamlessly link a wide array of services with APIs, creating a centralized hub for all your digital tools. Whether it's integrating automation functionalities, giving your personalized AI chatmodel access to use those services or adding UI controls for various services on your gavAI dashboard, the possibilities are endless.</p>
            
            <h3>Customizable API Integrations</h3>
            <p>Integrate any service with an API into your gavAI ecosystem. This could range from adding enhanced features to your chatbot, managing different applications directly from your gavAI dashboard, to syncing data across platforms. Our flexible integration system is designed to cater to your specific needs and workflows.</p>
            
            <h3>Centralized Control Panel</h3>
            <p>Transform your gavAI dashboard into a central command center. Manage and control all your integrated services from a single, user-friendly interface. This unified approach not only saves time but also streamlines your workflow, making your digital interaction more efficient and enjoyable.</p>
            
            <h3>Optional and User-Driven</h3>
            <p>At gavAI, we believe in giving you full control. All integrations are optional and customizable according to your preferences. You decide what to integrate and how, ensuring that your gavAI experience is tailored exactly to your requirements without any compromise.</p>
            
            <h3>Expand Your Capabilities</h3>
            <p>Unlock new potentials and functionalities by integrating your favorite tools . Our platform not only enhances your existing tools but also opens up new avenues for innovation and efficiency in your daily digital interactions.</p>
          </div>
        </section>
      </ContentContainer>
    );
};

export default Integrations;
