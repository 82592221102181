import React from 'react';
import { Link } from 'react-router-dom';
import ContentContainer from '../containers/ContentContainer';
import PricingContainer from '../containers/PricingContainer'; // Import the new component
import '../styles/ContentContainer.css';
import '../styles/Pricing.css';

const Pricing = () => {
  return (
    <>
      {/* Top Content Container */}
      <ContentContainer>
        <section className='section animated-fadeIn'>
          {/* Pricing Header Section */}
          <section className="section">
            <h1>Simple, Fair Pricing for Everyone</h1>
            <p>Choose the plan that's right for you, with options for every need and budget.</p>
            <p className="highlight">At gavAI, we don't just sell a service – we offer a commitment to privacy, innovation, and accessibility.</p>
          </section>

          {/* Why Choose Us Section */}
          <section className="section">
            <h2>Why gavAI?</h2>
            <p>
              Our services are crafted with the future in mind. Unlike others, we don't monetize your data. 
              Your subscription funds our research and development, giving you access to all current and future 
              products without compromising your privacy.
            </p>
          </section>
        </section>
      </ContentContainer>

      {/* Pricing Container */}
      <PricingContainer />
      
      {/* Bottom Content Container */}
      <ContentContainer>
      <p className='highlight-text-center'>
        Every plan guarantees top-tier security and unwavering protection of your data.
      </p>

        {/* Pricing Transparency Section */}
        <section className="section">
          <h2>Straight Talk About Pricing</h2>
          <p>
            Let’s be real: $60 a month is serious money. That’s why we’re keeping it real with you – 
            our service is straightforward: full access to our AI tools, no hidden fees, no privacy 
            intrusions.
          </p>
          <p>
            We respect your money and your trust. That's why the price includes taxes, no matter where 
            you're from. We handle the tax part, so you have one less thing to worry about. We believe in 
            fair and transparent pricing, no surprises.
          </p>
          <p>
            Here’s something you might not expect: we don't store your credit card info. Each month, 
            you’ll decide whether to renew. No automatic charges, no forgotten subscriptions eating into 
            your account. If you’re not actively using gavAI, you won’t pay for it. Simple as that.
          </p>
          <p>
            We're not just a service; we're a community that stands for honesty, respect, and real value. 
            Your feedback drives us, and we're committed to making gavAI better and more accessible for 
            everyone. Join us and be part of a movement that's about more than just profit – it’s about 
            making a positive impact.
          </p>
        </section>



        {/* FAQ Section */}
        <section className="section">
          <h2>Frequently Asked Questions</h2>

          <p><strong className="highlight">Q: What are the differences between the Free and Individual Plans?</strong></p>
          <p>The Free Plan allows access to a customizable AI chatmodel, but you need to provide your own API key for AI models. The Individual Plan, priced at $60/month, includes full access to all gavAI services without the need of an API key for the models. </p>

          <p><strong className="highlight">Q: What additional benefits does the Small Business Plan offer?</strong></p>
          <p>The Small Business Plan includes all the features of the Individual Plan, plus access to support for custom solution development. This plan is custom-priced and tailored to meet the specific needs of small businesses.</p>

          <p><strong className="highlight">Q: Can I use my own AI model API keys with the Individual Plan?</strong></p>
          <p>Currently, we do not support the use of personal AI model API keys in the Individual Plan. However, we're open to considering this feature based on user demand. If implemented in the future, we'll explore options for discounts or incentives for users incurring charges from their model providers, especially to support those with moderate to high usage needs.</p>
          
          <p><strong className="highlight">Q: What happens if I choose not to renew my subscription?</strong></p>
          <p>
            If you decide not to renew, you'll maintain full control over your data. This means you can still view, download, 
            or delete all your previously generated content, including chat histories, projects, and more. While you can continue 
            using your external APIs through your dashboard, you won't have access to AI models through gavAI without an active subscription. 
            However, you can use your own API keys for external models, but be aware that these models won't be able to execute APIs 
            integrated or created via our platform.
          </p>

          <p><strong className="highlight">Q: Is there a long-term commitment or contract required?</strong></p>
          <p>No, there is no long-term commitment or contract required. You can cancel your plan at any time without any extra fees or penalties.</p>

          <p><strong className="highlight">Q: How is billing handled for the Small Business Plan?</strong></p>
          <p>Billing for the Small Business Plan is based on custom pricing, which is determined after a consultation to understand your specific business needs and requirements.</p>

          <p><strong className="highlight">Q: Do you offer services for large corporations or enterprises?</strong></p>
          <p>
            At gavAI, our primary focus is on individuals and small businesses, tailoring our services to their specific needs. We are not currently equipped to 
            handle the complexities associated with large corporations or enterprises. In the event that we consider expanding our reach to include such entities in the future, 
            our decision will be firmly rooted in our core values. These include a deep commitment to privacy, the ethical use of AI, and prioritizing the well-being of not 
            just our users but everyone affected by our services and the decisions of our potential collaborators. We reserve the right to decline partnerships or service provision 
            to any organization; small or large, that fails to align with these fundamental principles. Our aim is to create and support a digital ecosystem that upholds the dignity and 
            privacy of individuals, encourages responsible AI practices, and prioritizes the collective wellbeing over profit.
          </p>
        </section>


          {/* Call to Action */}
          <div className="call-to-action-wrapper">
            <p className="highlight-text-center">
              Ready to embrace a future where your data remains yours? Join our waitlist today 
              and be part of the gavAI revolution.
            </p>
            <Link to="/waitlist" className="sign-up-button">Get Started</Link>
          </div>
        </ContentContainer>
    </>
  );
};

export default Pricing;