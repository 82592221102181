import React from 'react';
import ContentContainer from '../containers/ContentContainer';
import '../styles/About.css';
import CoupleGardeningSvg from '../assets/couple-gardening.svg';
import PeopleUsingTechnologySvg from '../assets/people-using-technology.svg';

const AboutValues = () => {
  return (
    <ContentContainer>
      <section className="values-section animated-fadeIn">
        <h1>Our Values at gavAI</h1>
        
        <div className="section fade-in">
          <img src={CoupleGardeningSvg} alt="Couple Gardening" className="img-left"/>
          <h2 className="highlight-text">Empowering Through AI</h2>
          <p>
            In our ideal future, AI becomes a catalyst for human freedom, not fear. A future where no one must toil in jobs that drain their spirit, but instead, everyone has the liberty to pursue their passions without the anxiety of making ends meet.
          </p>
          <p>
            We envision a world where work is a choice, not a necessity — where AI serves us by shouldering the burden of labor, opening a realm of creativity and exploration for all. It's a bold dream, but at gavAI, we're committed to taking the steps towards this vision, one innovation at a time.
          </p>
        </div>
        
        <div className="section fade-in">
          <img src={PeopleUsingTechnologySvg} alt="People Using Technology" className="img-right"/>
          <h2 className="highlight-text">Ethical AI for a Better World</h2>
          <p>
            We recognize the concerns and challenges posed by AI in today's workforce. That's why we're dedicated to pioneering solutions that complement and empower people, rather than render them obsolete.
          </p>
          <p>
            At the core of gavAI lies a commitment to ethical practice and the humane use of AI. We believe in leveraging artificial intelligence as a tool to enhance, not replace, human capabilities.
          </p>
        </div>

        {/* Updated section on Intellectual Property */}
        <div className="section fade-in">
          <h2 className="highlight-text">Your Creation, Your Rights</h2>
          <p>
            When you create something using gavAI, it belongs to you – completely and unequivocally. Your innovations, whether they are algorithms, artworks, or anything else you dream up, are your intellectual property. You have the freedom to monetize, share, or adapt these creations as you see fit.
          </p>
          <p>
            While there's no obligation to credit us for your creations, we ask that you use them in a way that aligns with our shared ethical values. This means leveraging your creations to respect privacy, promote inclusivity, and positively impact society.
          </p>
          <p>
            At gavAI, we don’t just provide tools; we nurture an ecosystem of responsible innovation. Your success, achieved through ethical and creative use of our platform, is a testament to our collective vision for a better, more inclusive future.
          </p>
        </div>
        
        <div className="section fade-in">
          <h2 className="highlight-text">Values Over Profit: Our Firm Stance</h2>
          <p>
            At gavAI, we firmly believe that our values are non-negotiable. Recognizing the importance of financial sustainability, we also understand that profit must not eclipse our core principles. Our dedication is steadfast to privacy, ethical AI use, and empowering individuals and communities.
          </p>
          <p>
            While we are not currently engaged in collaborations or partnerships, our ethos guides our future interactions and alliances. We are committed to connecting with organizations and entities that resonate with our vision and values when the time is right. For us, partnerships will be more than business transactions.
          </p>
          <p>
            We see the potential for powerful collective action and envision working alongside like-minded partners to advance technology responsibly. Our aim is to balance technological progression with ethical integrity and positive societal impact. In an era where profits often dominate, gavAI stands firm on value-driven innovation and conscientious growth.
          </p>
        </div>

        
      </section>
    </ContentContainer>
  );
};

export default AboutValues;
